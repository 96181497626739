<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              {{ $auth.activeCompany.companyName }}
            </div>
            <h2 class="page-title">QR Codes</h2>
          </div>
        </div>
      </div>

      <div class="page-body" v-if="loaded">
        <div class="card" v-if="codes.length == 0">
          <div class="card-header">
            <h3 class="card-title">Add Your First QR Code</h3>
          </div>
          <div class="card-body">
            <q-r-code-form @saved="loadCodes"></q-r-code-form>
          </div>
        </div>
        <div class="card mb-3" v-else>
          <div class="card-header d-flex">
            <h3 class="card-title me-auto">Add another QR Code</h3>
            <button
              class="btn btn-primary"
              v-if="!addCode"
              @click="addCode = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-plus"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 5l0 14"></path>
                <path d="M5 12l14 0"></path>
              </svg>
              Add A Code
            </button>
          </div>
          <div class="card-body" v-if="addCode">
            <q-r-code-form @saved="loadCodes"></q-r-code-form>
          </div>
        </div>

        <div
          class="card mb-3"
          v-for="code in codes"
          :key="`code-${code.urlid}`"
        >
          <Code @saved="loadCodes" @removed="loadCodes" :code="code"></Code>
        </div>

        <div class="card" v-if="!$auth.demoActive && $auth.superuser">
          <div class="card-body">
            <h3>Admin Code Settings</h3>
            <div class="mb-3">
              <label class="form-label">
                Dot scale ({{ codeSettings.dotScale }})
              </label>
              <input
                type="range"
                class="form-range mb-2"
                v-model="codeSettings.dotScale"
                min="0"
                max="1"
                step="0.05"
              />
            </div>

            <div class="mb-3">
              <label class="form-label">Code border color</label>
              <input
                type="color"
                class="form-control form-control-color"
                v-model="codeSettings.quietZoneColor"
              />
            </div>

            <div class="mb-3">
              <label class="form-label">Code accent color</label>
              <input
                type="color"
                class="form-control form-control-color"
                v-model="codeSettings.accentColor"
              />
            </div>

            <div class="d-flex">
              <button class="btn btn-danger me-auto" @click="resetView">
                Restore Defaults
              </button>
              <button class="btn btn-primary" @click="apply">
                Apply Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCodesService from "../../services/QRCodesService";

import QRCodeForm from "../../components/Profile/QRCodes/QRCodeForm.vue";
import Code from "../../components/Profile/QRCodes/Code.vue";

import TrackingService from "../../services/TrackingService";

export default {
  components: { QRCodeForm, Code },
  name: "QRCodes",
  data() {
    let d = {
      loaded: false,
      addCode: false,
      codes: [],
      codeSettings: {
        size: 1024 * 4,
        dotScale: 1,
        quietZoneColor: "#ffffff",
        accentColor: "black",
      },
    };

    if (window.localStorage.getItem("codesettings")) {
      try {
        d.codeSettings = JSON.parse(
          window.localStorage.getItem("codesettings")
        );
      } catch (e) {
        console.log(e);
      }
    }

    return d;
  },
  watch: {
    codeSettings: {
      handler() {
        window.localStorage.setItem(
          "codesettings",
          JSON.stringify(this.codeSettings)
        );
      },
      deep: true,
    },
  },
  methods: {
    apply() {
      window.location.reload();
    },
    resetView() {
      this.codeSettings.size = 1024 * 4;
      this.codeSettings.dotScale = 1;
      this.codeSettings.quietZoneColor = "#ffffff";
      this.codeSettings.accentColor = "black";
    },
    async loadCodes() {
      this.addCode = false;
      this.codes = await QRCodesService.getCodes();
    },
  },
  async mounted() {
    await this.loadCodes();
    this.loaded = true;

    TrackingService.track("viewed qr code builder");
  },
};
</script>
