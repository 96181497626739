import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getCodes() {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(
      `api/QRCodes/GetQRCodes?companyid=${auth.activeCompany.companyId}`,
      authHeaders
    );
    return res.data.map((c) => {
      c.baseLink = c.baseLink.trim();
      return c;
    });
  },
  async saveCode(code) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(`api/QRCodes/SaveQRCode`, code, authHeaders);
    return res.data;
  },
  async archiveCode(code) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(`api/QRCodes/ArchiveQRCode`, code, authHeaders);
    return res.data;
  },
};
