<template>
  <div class="row">
    <div class="col-lg-5 col-md-8">
      <div class="mb-3">
        <label class="form-label" for="code-name">Code Name</label>
        <input
          :class="['form-control', $v.code.name.$error ? 'is-invalid' : '']"
          name="code-name"
          id="code-name"
          type="text"
          v-model="$v.code.name.$model"
          required
        />
        <div class="invalid-feedback" v-if="!$v.code.name.required">
          A name for the code is required.
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label" for="code-url">Code URL</label>
        <input
          :class="['form-control', $v.code.url.$error ? 'is-invalid' : '']"
          name="code-url"
          id="code-url"
          type="text"
          v-model="$v.code.url.$model"
          required
        />
        <div class="invalid-feedback" v-if="!$v.code.url.required">
          A url for the code is required.
        </div>
      </div>

      <div class="mb-3" v-if="$auth.superuser">
        <label class="form-label" for="code-base-link">Base Link</label>
        <select
          :class="['form-select']"
          name="code-base-link"
          id="code-base-link"
          type="text"
          v-model="code.baseLink"
          required
        >
          <option value="a11.bz">a11.bz</option>
          <option value="www.mediabrains.com">www.mediabrains.com</option>
          <option
            v-for="site in $auth.activeCompany.sites"
            :key="`site-option-${site.siteID}`"
            :value="site.siteUrl"
          >
            {{ site.siteUrl }}
          </option>
        </select>
        <div class="form-text">
          <strong>(ADMIN ONLY)</strong> Base link is what the iphone shows when
          scanning a QRcode. Changing base link will change the code image but
          will not break existing codes.
        </div>
      </div>

      <div class="mb-3">
        <div class="d-flex mb-2">
          <label class="form-label me-2">Code Custom Image</label>
          <button class="btn btn-sm btn-danger" @click="uploadedImage = null">
            Remove Custom Image
          </button>
        </div>
        <drag-and-drop-upload-card
          v-model="uploadedImage"
          :label="`custom code image`"
          :helpText="`Custom image will be used as code image.`"
          :contentTypes="['png', 'jpg', 'jpeg', 'gif']"
          :inlinePreview="true"
          :uniqueId="`logo-custom-file`"
          :uploadTo="`images`"
          :resizeImage="false"
          :displayWidth="120"
          :displayHeight="120"
        ></drag-and-drop-upload-card>
        <div class="form-text">
          Square images look best in the QR code. Any image will be sized
          correctly.
        </div>
      </div>

      <div class="mb-3">
        <button class="btn btn-default me-3" @click="$emit('saved')">
          Cancel
        </button>
        <button class="btn btn-primary" @click="save" v-if="!saving">
          Save Code
        </button>
      </div>
    </div>

    <div class="col-lg-7 col-md-4">
      <h3>Create a QR code for your business.</h3>
      <p>
        Feature your company logo or any custom image on the code to brand it.
        <br />
        <strong>
          You can adjust any of these settings without breaking your code.
        </strong>
      </p>
      <p>
        Have confidence including your code in printed materials, show
        materials, etc. Once you create a code, you can change where your
        customers will go on the fly, without changing your QR code.
      </p>
      <button
        class="btn btn-danger"
        @click="archive"
        v-if="$auth.superuser && code.id"
      >
        Archive This Code
      </button>
    </div>
  </div>
</template>

<script>
import QRCodesService from "../../../services/QRCodesService";

import DragAndDropUploadCard from "../../../components/Content/Upload/DragAndDropUploadCard.vue";

import { required } from "vuelidate/lib/validators";
import TrackingService from "../../../services/TrackingService";

export default {
  name: "Profile.QRCodes.QRCodeForm",
  components: {
    DragAndDropUploadCard,
  },
  props: {
    edit: Object,
  },
  data() {
    let d = {
      saving: false,
      uploadedImage: null,
      code: {
        id: null,
        name: null,
        url: null,
        baseLink: "a11.bz",
        showLogo: 0,
        logoUrl: null,
      },
    };

    if (this.edit) {
      d.code.id = this.edit.urlid;
      d.code.name = this.edit.name;
      d.code.url = this.edit.url1;
      d.code.baseLink = this.edit.baseLink;
      d.code.showLogo = this.edit.showLogo;
      d.code.logoUrl = this.edit.logoUrl;
    }

    if (d.code.logoUrl) {
      d.uploadedImage = {
        baseUrl: `/api/clientimages/${d.code.logoUrl}`,
        serverName: d.code.logoUrl,
      };
    }

    return d;
  },
  watch: {
    uploadedImage() {
      if (this.uploadedImage && this.uploadedImage.serverName) {
        this.code.logoUrl = this.uploadedImage.serverName;
      } else {
        this.code.logoUrl = null;
      }
    },
  },
  methods: {
    async archive() {
      let c = window.confirm(
        "Are you sure? This can't be undone, but any of these codes you have shared are still scannable in the future, even if archived."
      );
      if (c) {
        TrackingService.track("archived code", {
          detail: this.code.name,
        });
        await QRCodesService.archiveCode({
          urlid: this.code.id,
          companyid: this.$auth.activeCompany.companyId,
        });
      }
      this.$emit("removed");
    },
    async save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saving = true;
        let saveCode = {
          name: this.code.name,
          url1: this.code.url,
          companyid: this.$auth.activeCompany.companyId,
          baseLink: this.code.baseLink.trim(),
          showLogo: this.code.showLogo,
          logoUrl: null,
        };

        if (this.uploadedImage && this.uploadedImage.serverName) {
          saveCode.logoUrl = this.code.logoUrl;
        } else if (this.uploadedImage && !this.uploadedImage.serverName) {
          saveCode.logoUrl = null;
        } else if (this.uploadedImage && !this.code.logoUrl) {
          saveCode.logoUrl = null;
        } else {
          saveCode.logoUrl = this.code.logoUrl;
        }

        if (this.code.id) {
          saveCode.urlid = this.code.id;
          TrackingService.track("update existing qr code", {
            detail: this.code.name,
          });
        } else {
          TrackingService.track("added new qr code", {
            detail: this.code.name,
          });
        }

        await QRCodesService.saveCode(saveCode);
        this.$emit("saved");
      }
    },
  },
  validations: {
    code: {
      name: {
        required,
      },
      url: {
        required,
      },
    },
  },
};
</script>
