<template>
  <div class="card-body row align-items-center" v-if="!editing">
    <div class="col-md-6">
      <h3 class="card-title">{{ code.name }}</h3>
      <div class="mb-3">
        <label
          flass="form-check form-check-inline"
          :for="`logo-toggle-${code.urlid}`"
        >
          <input
            type="checkbox"
            v-model="logoToggle"
            :id="`logo-toggle-${code.urlid}`"
          />
          Show
          <span v-if="code.logoUrl">custom image</span>
          <span v-else>company logo</span>
        </label>
      </div>
      <p>
        <strong>Code Links To:&nbsp;</strong>
        <a :href="code.url1" target="_blank">{{ code.url1 }}</a>
      </p>
      <p>
        <strong>Code Perma-Link:&nbsp;</strong>
        <a
          :href="`https://${code.baseLink.trim()}/s/${code.code}`"
          target="_blank"
        >
          https://{{ code.baseLink.trim() }}/s/{{ code.code }}
        </a>
      </p>
      <p>
        <strong>Code's Display Link:&nbsp;</strong>
        <a
          :href="`https://${code.baseLink.trim()}/s/${code.code}`"
          target="_blank"
        >
          {{ code.baseLink.trim() }}
        </a>
      </p>

      <div>
        <button class="btn btn-default me-2" @click.prevent="editing = true">
          Edit Code
        </button>

        <a :href="this.qrCode" class="btn btn-primary" download>
          Download Code
        </a>
      </div>
    </div>
    <div class="col-md-6 text-center">
      <img
        style="width: 164px; max-width: 100%"
        class="rounded shadow"
        :src="this.qrCode"
      />
    </div>
  </div>
  <div class="card-body" v-else>
    <q-r-code-form
      @saved="saved"
      @removed="removed"
      :edit="code"
    ></q-r-code-form>
  </div>
</template>

<script>
import QRCode from "easyqrcodejs";
import QRCodeForm from "./QRCodeForm.vue";

import ProfileService from "../../../services/ProfileService";
import QRCodesService from "../../../services/QRCodesService";

export default {
  components: { QRCodeForm },
  name: "Profile.QRCodes.Code",
  props: {
    code: Object,
  },
  data() {
    let d = {
      editing: false,
      qrCode: null,
      logoToggle: false,
      profile: null,
      codeSettings: {
        size: 1024 * 4,
        dotScale: 1,
        quietZoneColor: "white",
        accentColor: "black",
      },
    };

    if (window.localStorage.getItem("codesettings")) {
      try {
        d.codeSettings = JSON.parse(
          window.localStorage.getItem("codesettings")
        );
      } catch (e) {
        console.log(e);
      }
    }

    return d;
  },
  watch: {
    async code() {
      await this.logoToggleAction();
    },
    async logoToggle() {
      await this.logoToggleAction();
    },
  },
  methods: {
    async logoToggleAction() {
      if (!this.profile) {
        this.profile = await ProfileService.getProfile({
          company: this.$auth.activeCompany,
        });
      }

      if (this.logoToggle) {
        this.toggleLogo(true);
        if (this.code.logoUrl) {
          this.qrCode = await this.generateQRCode(
            `/api/clientimages/${this.code.logoUrl}`
          );
        } else {
          this.qrCode = await this.generateQRCode(`/${this.profile.baseUrl}`);
        }
      } else {
        this.qrCode = await this.generateQRCode(null);
        this.toggleLogo(false);
      }
    },
    async toggleLogo(state) {
      await QRCodesService.saveCode({
        name: this.code.name,
        urlid: this.code.urlid,
        companyid: this.code.companyid,
        baseLink: this.code.baseLink.trim(),
        url1: this.code.url1,
        logoUrl: this.code.logoUrl,
        showLogo: state ? 1 : 0,
      });
    },
    generateQRCode(image) {
      return new Promise((resolve) => {
        let width = this.codeSettings.size;
        let height = this.codeSettings.size;
        let dotScale = this.codeSettings.dotScale;
        var options = {
          text: `https://${this.code.baseLink.trim()}/s/${this.code.code}`,
          width: width,
          height: height,
          dotScale: dotScale, // For body block, must be greater than 0, less than or equal to 1. default is 1

          quietZone: width / 16,
          quietZoneColor: this.codeSettings.quietZoneColor,

          PO: this.codeSettings.accentColor,
          PI: this.codeSettings.accentColor,

          AO: this.codeSettings.accentColor,
          AI: this.codeSettings.accentColor,

          timing: this.codeSettings.accentColor,

          colorDark: "black",

          crossOrigin: "anonymous",
          onRenderingEnd: (_, data) => resolve(data),
        };
        if (image) {
          options.logo = image;
          options.logoBackgroundTransparent = true;
        }
        try {
          new QRCode(document.createElement("div"), options);
        } catch {
          delete options.logo;
          new QRCode(document.createElement("div"), options);
        }
      });
    },
    removed() {
      this.editing = false;
      this.$emit("removed");
    },
    saved() {
      this.editing = false;
      this.$emit("saved");
    },
  },
  async mounted() {
    if (this.code.showLogo == 1) {
      this.logoToggle = true;
    } else {
      this.qrCode = await this.generateQRCode(null);
    }
  },
};
</script>
